<template>
    <div>
        <v-row style="margin-top: 70px;">
            <v-col cols="12" sm="1" lg="1"></v-col>
            <v-col cols="12" sm="10" lg="4" class="text-logi" >
                <h1 class="green-title">Notre mission</h1>
                <h4> Implants BioTagSensor </h4>
                <p style="text-align: justify;"> Donner à toutes et tous (malades, personnes à risques ou sportif amateur) l’accès à un outils ultra discret, très sécurisé, autonome et super performant en garantissant des mesures de niveaux médicales de paramètres santé classiques (température, mouvement, BPM, O2, pression artérielle, PPG) </p>
                <h4> Implants Plateforme </h4>
                <p style="text-align: justify;"> Offrir à nos clients et partenaires une plateforme agile et robuste (basée sur l’implant BioTagSensor) et notre support pour l’étude et l’intégration de nouveaux capteurs, pour penser l’avenir de la santé. </p>
            </v-col>
            <v-col cols="12" sm="1" lg="2"></v-col>
            <v-col cols="12" sm="4" lg="4">
                <div class="img-mission">
                    <img src="../images/medecin_ordi.jpg" alt="cage équipée">
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="1" lg="1"></v-col>
            <v-col cols="12" sm="10" lg="4">
                <div class="img-equipe">
                    <img src="../images/coaching-equipe.jpg" alt="cage équipée">
                </div>
            </v-col>
            <v-col cols="12" sm="1" lg="2"></v-col>
            <v-col cols="12" sm="4" lg="4" class="text-logi" >
                <h1 class="green-title">Notre equipe</h1>
                <p style="text-align: justify;">Composée à la base d’ingénieurs passionnés et de spécialistes Hardware et Software, l’équipe est rejointe par des profils complémentaires pour garantir le résultat de la mission que nous nous sommes fixés : professeurs en médecine spécialisée, chercheurs en biologie, docteur généraliste. <br> <br>
                    L’équipe est aujourd’hui internationale et pluri-disciplinaire et tous orientée vers le renouveau de la mesure médicale au bénéfice du malade ou des personnes à risques ou simplement aux sportifs.
                </p>
            </v-col>
        </v-row>
        <foot/>
    </div>   
</template>

<script>
import foot from '@/components/Footer.vue'
export default {
    components:{
      foot
    },
}
</script>

<style>

.img-mission{
    margin-top: 50px;
}

.img-mission img{
    width: 90%;
}

.img-equipe img{
    width: 90%;
}

.text-logi h1{
    margin-bottom: 20px;
}
.text-logi h4{
    font-family: 'roboto' sans-serif;
    font-size: 20px;
    color: var(--violet);
    width: 100%;
    margin-bottom: 15px;
}

</style>